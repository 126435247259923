<template>
    <div class="grid"> 
        <div class="col-12 md:col-12 lg:col-12">
            <div class="card" style="float:left; width:100%">
                <Toolbar class="p-0 pl-0 pr-0">
					<template v-slot:start> 
                        <Button v-if="checkPermission(moduleId,'insert')" v-tooltip.top="'Add New'" :label="showBtnLabels ? 'Add New' : ''" @click="loadForm" icon="pi pi-plus" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-primary mr-2" /> 
                        <Button v-if="checkPermission(moduleId,'view')" v-tooltip.top="'Refresh'" :label="showBtnLabels ? 'Refresh' : ''" icon="pi pi-refresh" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-success mr-2"   @click="getRecords" /> 
                        <!-- <Button v-if="checkPermission(moduleId,'delete')" v-tooltip.top="'Delete Selected'" :label="showBtnLabels ? 'Delete Selected' : ''" icon="pi pi-trash" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-danger mr-2"  @click="deleteRecord" :disabled="!selectedFoldersAndFiles || !selectedFoldersAndFiles.length" /> -->
                        <Button v-if="checkPermission(moduleId,'view')" v-tooltip.top="'Helping Material'" :label="showBtnLabels ? 'Help' : ''" @click="showHelp = true" icon="pi pi-question" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-info mr-2"  /> 
                        <Button v-if="checkPermission(moduleId,'view')" v-tooltip.top="'Search'" :label="showBtnLabels ? 'Search' : ''" @click="coverLayout = !coverLayout" icon="pi pi-search" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-secondary mr-2"  /> 
                        <Button v-if="checkPermission(moduleId,'insert')" v-tooltip.top="'Upload Files'" :label="showBtnLabels ? 'Upload Files' : ''" @click="showUploadFilesForm = true;addViewInURL({view: 'add-file', id:this.selectedRecordId, path:breadCrumb, openedFolder:openedFolder})" icon="pi pi-upload" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-warning mr-2"  />
                        <MultiSelect @change="getRecords()" v-model="selected_file_categories" :filter="true" :options="fileCategories" optionLabel="name" optionValue="id" placeholder="Select Category" /> 
                    </template> 
                    <template v-slot:end> 
                        <div class="mydrive-breadcurmb">
                            <div class="goback">
                                <Button  v-if="checkPermission(moduleId,'view') && Object.keys(breadCrumb).length > 0" v-tooltip.top="'Go Back'" :label="showBtnLabels ? 'Go Back' : ''" @click="goBack()" icon="pi pi-arrow-left" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-warning mr-2"  /> 
                            </div>
                            <div class="breadcrumb mt-2">  
                                <Breadcrumb :home="MyDriveHome" :model="breadCrumb">
                                    <template #item="{item,key}">
                                        <a :class=" item.id > 0 && key <= (Object.keys(breadCrumb).length-2)? 'link' : ''"  > <span v-if="item.name"><span class="pi pi-folder"></span> {{item.name}}</span><span v-else><span class="pi pi-circle-fill"></span> My Drive</span></a>
                                    </template>
                                </Breadcrumb>
                            </div>
                        </div>
                    </template> 
				</Toolbar>  
            </div>  
        </div> 
		<div class="col-12"> 
			<div class="card my-drive"> 
                <div v-if="coverLayout" class="coverLayout"> 
                    <div class="card widget-expenses coverLayout">
                        <div class=card-header>
                            <InputText id="search" @keyup="searchFilesAndFolders()" placeholder="Type atleast 3 characters to start search" v-model="searchText"  type="text" class="full-width"/>
                            <div>
                                <Button icon="pi pi-times" class="p-button-rounded closeCoverLayoutBtn p-button-text p-button-plain" @click="coverLayout=false"></Button> 
                            </div>
                        </div>
                        <div class="card-subheader mb-2 pb-3">
                           {{searchMessage}}
                        </div> 
                        <div v-if="!searching">
                            <div v-if="searchedData && searchedData.length">
                                <div v-for="(file, key) in searchedData" :key="key" class="flex justify-content-between align-items-center my-2 item">
                                    <div @click="viewSearchedFile();" :recordId="file.id" :folderId="file.table_id" type="file"  class="flex flex-row "> 
                                        <div class="searchedItemNumber">{{key+1}} - </div>
                                        <div class="searchedItemContent">
                                            <h4 class="searchedItemName mb-1">
                                                <span v-if="videoTypes.includes(file.file_type)" class="pi pi-video"></span>
                                                <span v-else-if="pdfTypes.includes(file.file_type)" class="fas fa-file-pdf"></span>
                                                <span v-else-if="documentTypes.includes(file.file_type)" class="fas fa-file-word"></span>
                                                <span v-else-if="textTypes.includes(file.file_type)" class="fa-solid fa-file-lines"></span>
                                                <span v-else-if="excelTypes.includes(file.file_type)" class="fas fa-file-excel"></span>
                                                <span v-else-if="pptTypes.includes(file.file_type)" class="pi pi-ppt"></span>
                                                {{file.file_name}}
                                            </h4> 
                                            <Breadcrumb class="breadcrumb searchedItemBreadcrumb" v-if="file.breadcrumb && file.breadcrumb.length" :home="MyDriveHome" :model="file.breadcrumb">
                                                <template #item="{item,key}">
                                                    <span :class=" item.id > 0 && key <= (Object.keys(breadCrumb).length-2)? 'link' : ''"  > <span v-if="item.name"><span class="pi pi-folder"></span> {{item.name}}</span><span v-else><span class="pi pi-circle-fill"></span> My Drive</span></span>
                                                </template>
                                            </Breadcrumb>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                            <div class="no-record-found" v-else>
                                <h4>No Record Found!</h4>
                            </div>
                        </div>
                        <div v-else>
                            <div class="col-12 md:col-12 lg:col-12" style="float:left">
                                <div><Skeleton height="3rem"></Skeleton></div>
                            </div>
                            <div class="col-12 md:col-12 lg:col-12" style="float:left">
                                <div><Skeleton height="3rem"></Skeleton></div>
                            </div>
                            <div class="col-12 md:col-12 lg:col-12" style="float:left">
                                <div><Skeleton height="1rem"></Skeleton></div>
                            </div>
                            <div class="col-12 md:col-12 lg:col-12" style="float:left">
                                <div><Skeleton height="3rem"></Skeleton></div>
                            </div>
                            <div class="col-12 md:col-12 lg:col-12" style="float:left">
                                <div><Skeleton height="3rem"></Skeleton></div>
                            </div>
                            <div class="col-12 md:col-12 lg:col-12" style="float:left">
                                <div><Skeleton height="1rem"></Skeleton></div>
                            </div>
                            <div class="col-12 md:col-12 lg:col-12" style="float:left">
                                <div><Skeleton height="3rem"></Skeleton></div>
                            </div>
                            <div class="col-12 md:col-12 lg:col-12" style="float:left">
                                <div><Skeleton height="3rem"></Skeleton></div>
                            </div>
                            <div class="col-12 md:col-12 lg:col-12" style="float:left">
                                <div><Skeleton height="1rem"></Skeleton></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="driveLoading" v-if="loading">
                        <div class="col-12 md:col-9 lg:col-9" style="float:left">
                            <div><Skeleton height="1rem"></Skeleton></div>
                        </div>
                        <div class="col-12 md:col-3 lg:col-3" style="float:left">
                            <div><Skeleton height="1rem"></Skeleton></div>
                        </div>
                        <div class="col-12 md:col-3 lg:col-3" style="float:left">
                            <div><Skeleton height="5rem"></Skeleton></div>
                        </div>
                        <div class="col-12 md:col-3 lg:col-3" style="float:left">
                            <div><Skeleton height="5rem"></Skeleton></div>
                        </div>
                        <div class="col-12 md:col-3 lg:col-3" style="float:left">
                            <div><Skeleton height="5rem"></Skeleton></div>
                        </div> 
                        <div class="col-12 md:col-3 lg:col-3" style="float:left">
                            <div><Skeleton height="5rem"></Skeleton></div>
                        </div> 
                        <Divider />
                        <div class="col-12 md:col-9 lg:col-9" style="float:left">
                            <div><Skeleton height="1rem"></Skeleton></div>
                        </div>
                        <div class="col-12 md:col-3 lg:col-3" style="float:left">
                            <div><Skeleton height="1rem"></Skeleton></div>
                        </div>
                        <div class="col-12 md:col-2 lg:col-2" style="float:left">
                            <div><Skeleton height="20rem"></Skeleton></div>
                        </div>
                        <div class="col-12 md:col-2 lg:col-2" style="float:left">
                            <div><Skeleton height="20rem"></Skeleton></div>
                        </div>
                        <div class="col-12 md:col-2 lg:col-2" style="float:left">
                            <div><Skeleton height="20rem"></Skeleton></div>
                        </div> 
                        <div class="col-12 md:col-2 lg:col-2" style="float:left">
                            <div><Skeleton height="20rem"></Skeleton></div>
                        </div> 
                        <div class="col-12 md:col-2 lg:col-2" style="float:left">
                            <div><Skeleton height="20rem"></Skeleton></div>
                        </div> 
                        <div class="col-12 md:col-2 lg:col-2" style="float:left">
                            <div><Skeleton height="20rem"></Skeleton></div>
                        </div>  
                    </div>
                    <Fieldset v-else :legend="openedFolder > 0 && Object.keys(breadCrumb).length ? breadCrumb[Object.keys(breadCrumb).length-1].name : 'My Drive'">
                    <div v-if="records && Object.keys(records).length > 0" class="col-12"  style="float:left"> 
                        <Toolbar class="p-0 pl-0 pr-0">
					        <template v-slot:start> 
                                <div class="col-12"><h3>Folders <span>({{Object.keys(records).length}})</span></h3></div>
                            </template> 
                            <template v-slot:end>
                                <div class="col-12">
                                    <div style="float:left" v-if="clearFoldersSelection"><Button  v-tooltip.top="'Clear All'" :label="showBtnLabels ? 'Clear All' : ''" @click="clearAllSelectedFolders()" icon="pi pi-trash" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-md' : 'p-button-md']]" class="p-button-raised p-button-danger mr-2" /> </div>
                                    <div class="field-checkbox">
                                        <TriStateCheckbox  id="binary" @change="selectAllFolders()"  v-model="selectedFoldersCheckbox"  />
                                        <label for="binary"><h5 class="m-0 p-0"><span v-if="Object.keys(selectedFoldersAndFiles).length">{{foldersSelected()}} Folders Selected</span><span v-else>{{selectedFolderCheckboxValue}}</span></h5></label>
                                    </div>
                                </div>
                            </template> 
                        </Toolbar>
                        <div v-for="(folder,key) in records" :key="key" class="col-12 md:col-3 lg:col-3 folder" style="float:left">
                            <div :class="selectedFoldersAndFiles.includes('folder_'+folder.id) ? 'selected' : ''" class="card mydrive-data-block"  @click="selectFolder(folder.id)" :recordId="folder.id" type="folder" @contextmenu="onRightClick"> 
                                <div class="content" @click="selectFolder(folder.id)"  @dblclick="openFolder(folder.id)">  
                                    <div class="name" v-if="folder.name"><span class="fa-solid fa-folder-plus icon"></span> <span v-if="folder.name.length > 60">{{shortStr(folder.name,0,60)}}...</span> <span v-else>{{folder.name}}</span> (<small>{{folder.total_files+folder.total_child_folders}}</small>) </div>
                                </div>
                            </div>
                        </div>  
                        <ContextMenu ref="folderContextMenu" :model="actions" />
                    </div>
                    <div v-if="files && Object.keys(files).length > 0"  class="col-12" style="float:left">
                        <Divider v-if="records && Object.keys(records).length > 0" />
                        <Toolbar class="p-0 pl-0 pr-0">
					        <template v-slot:start> 
                                <div class="col-12"><h3>Files <span>({{Object.keys(files).length}})</span></h3></div>
                            </template> 
                            <template v-slot:end>
                                <div class="col-12">
                                    <div style="float:left" v-if="clearFilesSelection"><Button  v-tooltip.top="'Clear All'" :label="showBtnLabels ? 'Clear All' : ''" @click="clearAllSelectedFiles()" icon="pi pi-trash" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-md' : 'p-button-md']]" class="p-button-raised p-button-danger mr-2" /> </div>
                                    <div class="field-checkbox">
                                        <TriStateCheckbox  id="binary" @change="selectAllFiles()"  v-model="selectedFileCheckbox"  />
                                        <label for="binary"><h5 class="m-0 p-0"><span v-if="Object.keys(selectedFoldersAndFiles).length">{{fileseSelected()}} Files Selected</span><span v-else>{{selectedFileCheckboxValue}}</span></h5></label>
                                    </div>
                                </div>
                            </template> 
                        </Toolbar> 
                        <div v-for="(file,key) in files" :key="key" class="col-12 md:col-3 lg:col-2 file" style="float:left">
                            <div  :class="selectedFoldersAndFiles.includes('file_'+file.id) ? 'selected' : ''" class="card mydrive-data-block"  @click="selectFile(file.id)" :recordId="file.id" :folderId="file.table_id" type="file" :contextKey="key" @scroll.prevent @contextmenu="onRightClick"> 
                                <div > 
                                    <div class="image" v-if="imageTypes.includes(file.file_type)">
                                        <img :src="filesUrl+'mdrive/'+file.original_name" />
                                    </div>
                                    <div class="icon" v-else> 
                                        <span v-if="videoTypes.includes(file.file_type)" class="pi pi-video"></span>
                                        <span v-else-if="pdfTypes.includes(file.file_type)" class="fas fa-file-pdf"></span>
                                        <span v-else-if="documentTypes.includes(file.file_type)" class="fas fa-file-word"></span>
                                        <span v-else-if="textTypes.includes(file.file_type)" class="fa-solid fa-file-lines"></span>
                                        <span v-else-if="excelTypes.includes(file.file_type)" class="fas fa-file-excel"></span>
                                        <span v-else-if="pptTypes.includes(file.file_type)" class="pi pi-ppt"></span>
                                    </div>
                                    <div class="name" v-if="file.file_name"><span v-if="file.file_name.length > 30">{{shortStr(file.file_name.split(".")[0],0,30)}}...{{file.file_name.split(".")[1]}}</span> <span v-else>{{file.file_name}}</span> <br/> <small>({{ calculateFileSize(file.file_size)}})</small></div>
                                </div>
                            </div>
                            <ContextMenu v-if="file.shared_file == 'No'"  :ref="'fileContextMenu'+key" :model="fileActions" />
                            <ContextMenu v-else :ref="'fileContextMenu'+key" :model="sharedFileActions" />
                        </div>
                        
                    </div>
                    <div  v-if=" (records && Object.keys(records).length > 0 ) || (files && Object.keys(files).length > 0)"></div>
                    <div class="no-record-found" v-else>
                        <h3 class="">No Data Found!</h3>
                    </div>
                    </Fieldset> 
                </div>
			</div>
		</div>


        <Sidebar v-model:visible="detailSidebar" @hide="closeDetailSidebar();" :position="isRTL ? 'left' : 'right'" :showCloseIcon="true"
		:baseZIndex="1000" class="layout-rightmenu p-sidebar-sm fs-small py-3 " :class="isRTL ? 'pl-0 pr-3' : 'pl-3 pr-0'">
            <div class="online-members flex flex-column mt-3" :class="{'ml-3': !isRTL, 'mr-3': isRTL}"> 
                <div v-if="!folderFileViewLoading" class="my-drive"> 
                    <div v-if="selectedFolderData && Object.keys(selectedFolderData).length > 0">
                        <div class="col-12 md:col-12 lg:col-12 folderCard"  >
                            <div  class="card mydrive-data-block"> 
                                <div class="" @dblclick="openFolder(folder.id)"> 
                                    <div class="icon">
                                        <span class="pi pi-folder"></span>
                                    </div>
                                    <div class="name">{{selectedFolderData.name}} <br/>(<small>{{selectedFolderData.total_files+selectedFolderData.total_child_folders}}</small>) </div>
                                </div>   
                            </div>
                            <div  class="card mydrive-data-block"> 
                                <table>
                                    <tr>
                                        <th>Size</th>
                                        <td>{{calculateFileSize(selectedFolderData.total_size)}}</td>
                                    </tr>
                                    <tr>
                                        <th>Sub Folders</th>
                                        <td>{{selectedFolderData.total_child_folders}}</td>
                                    </tr>
                                    <tr>
                                        <th>Files </th>
                                        <td>{{selectedFolderData.total_files}}</td>
                                    </tr> 
                                </table>
                            </div>
                            <div  class="card mydrive-data-block"> 
                                <table>
                                    <tr>
                                        <th>Status</th>
                                        <td>{{selectedFolderData.status}}</td>
                                    </tr>
                                    <tr>
                                        <th>Date Created</th>
                                        <td>{{selectedFolderData.date_added}}</td>
                                    </tr>
                                    <tr>
                                        <th>Created By</th>
                                        <td>{{selectedFolderData.added_by}}</td>
                                    </tr>
                                    <tr>
                                        <th>Date Modified</th>
                                        <td>{{selectedFolderData.date_modification}}</td>
                                    </tr>
                                    <tr>
                                        <th>Modified By</th>
                                        <td>{{selectedFolderData.modified_by}}</td>
                                    </tr>
                                </table>
                            </div>
                            <div  class="card mydrive-data-block" style="text-align:center"> 
                                <Button type="button" label="Update"  icon="pi pi-pencil" @click="showForm=true;addViewInURL({view: 'add-folder', id:this.selectedRecordId})" class="p-button-raised p-button-lg p-button-warning mr-2"/>
                                <Button type="button" label="Delete" icon="pi pi-times" @click="deleteRecord()" class="p-button-raised p-button-lg p-button-danger mr-2"/>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="selectedFileData && Object.keys(selectedFileData).length > 0">
                        <div class="col-12 md:col-12 lg:col-12 fileCard"  >
                            <div  class="card mydrive-data-block"> 
                                <div class=""> 
                                    <div class="image" v-if="imageTypes.includes(selectedFileData.file_type)">
                                        <img :src="filesUrl+'mdrive/'+selectedFileData.original_name" />
                                    </div>
                                    <div class="icon" v-else> 
                                        <span v-if="videoTypes.includes(selectedFileData.file_type)" class="pi pi-video"></span>
                                        <span v-else-if="pdfTypes.includes(selectedFileData.file_type)" class="fas fa-file-pdf"></span>
                                        <span v-else-if="documentTypes.includes(selectedFileData.file_type)" class="fas fa-file-word"></span>
                                        <span v-else-if="textTypes.includes(selectedFileData.file_type)" class="fa-solid fa-file-lines"></span>
                                        <span v-else-if="excelTypes.includes(selectedFileData.file_type)" class="fas fa-file-excel"></span>
                                        <span v-else-if="pptTypes.includes(selectedFileData.file_type)" class="pi pi-ppt"></span>
                                    </div>
                                    <div class="name">{{selectedFileData.file_name}}</div> 
                                </div>   
                            </div>
                            <div  class="card mydrive-data-block" v-if="selectedFileData.description"> 
                                <p>{{selectedFileData.description}}</p>
                            </div>
                            <div  class="card mydrive-data-block"> 
                                <table>
                                    <tr>
                                        <th>Size</th>
                                        <td>{{calculateFileSize(selectedFileData.file_size)}}</td>
                                    </tr> 
                                    <tr>
                                        <th>Type</th>
                                        <td>{{selectedFileData.file_type}}</td>
                                    </tr>
                                    <tr>
                                        <th>Category</th>
                                        <td>{{selectedFileData.file_category}}</td>
                                    </tr>
                                    <tr>
                                        <th>Expiry Date</th>
                                        <td>{{formatDate(selectedFileData.expiry_date)}}</td>
                                    </tr>
                                </table>
                            </div> 
                            <div  class="card mydrive-data-block"> 
                                <table>
                                    <tr>
                                        <th>Status</th>
                                        <td>{{selectedFileData.status}}</td>
                                    </tr>
                                    <tr>
                                        <th>Date Created</th>
                                        <td>{{formatDateTime(selectedFileData.date_added)}}</td>
                                    </tr>
                                    <tr>
                                        <th>Created By</th>
                                        <td>{{selectedFileData.added_by}}</td>
                                    </tr>
                                    <tr>
                                        <th>Date Modified</th>
                                        <td>{{formatDateTime(selectedFileData.date_modification)}}</td>
                                    </tr>
                                    <tr>
                                        <th>Modified By</th>
                                        <td>{{selectedFileData.modified_by}}</td>
                                    </tr>
                                </table>
                            </div>
                            <div  class="card mydrive-data-block" style="text-align:center"> 
                                <a v-if="this.checkPermission(this.moduleId,'update')" target="_blank" :href="filesUrl+'mdrive/'+selectedFileData.original_name">
                                    <Button type="button" label="Download"  icon="pi pi-download"  class="p-button-raised p-button-lg p-button-success mr-2"/>
                                </a>
                                <Button type="button" label="Update" v-if="selectedFileData.shared_file != 'Yes'"  icon="pi pi-pencil" @click="showFileForm=true;addViewInURL({view: 'update-file', id:this.selectedRecordId,folderId:this.selectedFileFolderId})" class="p-button-raised p-button-lg p-button-warning mr-2"/>
                                <Button type="button" label="Delete" v-if="selectedFileData.shared_file != 'Yes'" icon="pi pi-times" @click="deleteRecord()" class="p-button-raised p-button-lg p-button-danger mr-2"/>
                            </div>
                        </div>
                    </div>
                    <div class="no-record-found" v-else>
                        <h3 class="">No Data Found!</h3>
                    </div>
                </div>
                <div v-else>
                    <div class="col-12 md:col-12 lg:col-12" style="float:left">
                        <div><Skeleton height="25rem"></Skeleton></div>
                    </div>
                    <div class="col-12 md:col-12 lg:col-12" style="float:left">
                        <div><Skeleton height="2rem"></Skeleton></div>
                    </div>
                    <div class="col-12 md:col-12 lg:col-12" style="float:left">
                        <div><Skeleton height="2rem"></Skeleton></div>
                    </div> 
                    <div class="col-12 md:col-12 lg:col-12" style="float:left">
                        <div><Skeleton height="2rem"></Skeleton></div>
                    </div> 
                    <div class="col-12 md:col-12 lg:col-12" style="float:left">
                        <div><Skeleton height="2rem"></Skeleton></div>
                    </div> 
                    <div class="col-12 md:col-12 lg:col-12" style="float:left">
                        <div><Skeleton height="10rem"></Skeleton></div>
                    </div> 
                </div>
            </div>
        </Sidebar> 

         <Sidebar v-model:visible="moveFileSidebar" @hide="moveFileSidebar=false" :position="isRTL ? 'left' : 'right'" :showCloseIcon="true"
		:baseZIndex="1000" class="layout-rightmenu p-sidebar-sm fs-small py-3 " :class="isRTL ? 'pl-0 pr-3' : 'pl-3 pr-0'">
            <div class="online-members flex flex-column mt-3" :class="{'ml-3': !isRTL, 'mr-3': isRTL}"> 
                <div v-if="!fetchingFoldersTree" class="my-drive"> 
                    <div v-if="allFoldersTree && Object.keys(allFoldersTree).length > 0">
                        <div class="col-12 md:col-12 lg:col-12 folderCard"  >
                            <ProgressBar v-if="movedPercentage" :value="movedPercentage" style="height:20px" :showValue="true" /> <br />
                            <Tree  selectionMode="single"  v-model:selectionKeys="moveToFolderId" :value="allFoldersTree" :filter="true" filterMode="lenient"></Tree> 
                            <div  class="card mydrive-data-block" style="text-align:center"> 
                                <Button @click="moveSelectedItem()" :disabled="moveToFolderId ? false : true" type="button" label="Move Now"  icon="pi pi-arrow-up"  class="p-button-raised p-button-lg p-button-warning mr-2"/> 
                            </div>
                        </div>
                    </div> 
                    <div class="no-record-found" v-else>
                        <h3 class="">No Data Found!</h3>
                    </div>
                </div>
                <div v-else> 
                    <div class="col-12 md:col-12 lg:col-12" style="float:left">
                        <div><Skeleton height="2rem"></Skeleton></div>
                    </div>
                    <div class="col-12 md:col-12 lg:col-12" style="float:left">
                        <div><Skeleton height="2rem"></Skeleton></div>
                    </div> 
                    <div class="col-12 md:col-12 lg:col-12" style="float:left">
                        <div><Skeleton height="2rem"></Skeleton></div>
                    </div> 
                    <div class="col-12 md:col-12 lg:col-12" style="float:left">
                        <div><Skeleton height="2rem"></Skeleton></div>
                    </div> 
                    <div class="col-12 md:col-12 lg:col-12" style="float:left">
                        <div><Skeleton height="10rem"></Skeleton></div>
                    </div> 
                </div>
            </div>
        </Sidebar> 

        <Toast />
        <ConfirmDialog group="dialog" />
        <!-- form loading -->
        <UploadFiles v-on:formClose="closeUploadFilesForm();removeViewFromURL('add-file');" v-if="showUploadFilesForm  && (checkPermission(moduleId,'insert') || checkPermission(moduleId,'update'))" :uploadPath="breadCrumb" :openedFolder="openedFolder" :recordId="selectedRecordId" :showForm="true" /> 
        <FileForm  v-on:formClose="closeFileForm();removeViewFromURL('update-file');" v-if="showFileForm  && (checkPermission(moduleId,'insert') || checkPermission(moduleId,'update'))" :openedFolder="selectedFileFolderId" :recordId="selectedRecordId" :showForm="true" /> 
        <FolderForm  v-on:formClose="closeForm();removeViewFromURL('add-folder');" v-if="showForm  && (checkPermission(moduleId,'insert') || checkPermission(moduleId,'update'))" :openedFolder="openedFolder" :recordId="selectedRecordId" :showForm="true" /> 
        <ModuleHelp  v-on:closeHelp="showHelp = false" v-if="showHelp  && checkPermission(moduleId,'view')" :moduleId="moduleId"/>
        
        <!-- To download files  -->
        <a id="downloadURL" :href="downloadURL" target="_blank"></a>
    </div>
</template>
<script>
import UploadFiles from '../../components/MDrive/UploadFiles.vue';
import FileForm from '../../components/MDrive/FileForm.vue';
import FolderForm from '../../components/MDrive/FolderForm.vue';
import ModuleHelp from '../../components/Help/ModuleHelp.vue'; 
export default {
    data() {
			return {  
                module_name:'My Drive', 
                actions: [], 
                fileActions: [], 
                moduleId:59,
                showHelp : false, 
                isSaving:false, 
                coverLayout:false,
				records: null,  
                files:[],
				loading: true,  
                searching:false,
				selectedRecords: null,
				columns: null,
                selectedColumns: null, 
                downloadURL:'',
                downloadFileName:'',
                showForm:false,
                showFileForm:false,
                selectedRecordId: 0,  
                selectedRecordType: '',
                openedFolder:0,
                folderFileViewLoading:false,
                saveAndClose:false, 
                detailSidebar:false,
                showUploadFilesForm:false,
                selectedFileFolderId:0,
                clearFilesSelection:false,
                selectedFileCheckbox:null, 
                clearFoldersSelection:false,
                selectedFoldersCheckbox: null,
                selectedFileCheckboxValue:'Select All',
                selectedFolderCheckboxValue: 'Select All',
                selectedFoldersAndFiles:[],
                selectedFileData:[],
                selectedFiles:[],
                breadCrumb: [],
                sharedFileActions:[],
                selectedFolderData:[],
                imageTypes: ['png','jpg','jpeg','svg','webp','tif','tiff','bmp','eps'],
                videoTypes: ['mp4','mkv','mov','wmv','avi','flv','swf','webm','mpeg-2'],
                pdfTypes: ['odt','pdf','ods'],
                documentTypes: ['doc','docx'],
                excelTypes: ['xls','xlx','xlsx','csv'],
                pptTypes: ['ppt','pptx','xlsx'],
                textTypes: ['txt'],
                MyDriveHome: { label: 'My Drive', icon: 'pi pi-circle-fill'}, 
                searchMessage:'',
                allFoldersTree:[],
                moveToFolderId:0,
                fetchingFoldersTree:false,
                moveFileSidebar:false, 
                movedPercentage:0,
                fileCategoriesLoading:false,
                fileCategories:[],
                selected_file_categories:[],
			}
		},  
        components:{
            FolderForm,
            FileForm,
            UploadFiles,
            ModuleHelp
        },
		created() { 
            if(this.checkPermission(this.moduleId,'view')){
                this.actions.push({
                    label: 'Open',
                    icon: 'fa-solid fa-folder-open',
                    command: () => {
                        this.getClickedRecord('open');
                    }
                });
            }   

            if(this.checkPermission(this.moduleId,'update')){
                this.actions.push({
                    label: 'Update',
                    icon: 'pi pi-refresh',
                    command: () => {
                        this.getClickedRecord('update');
                    }
                });
            }
            if(this.checkPermission(this.moduleId,'view')){
                this.actions.push({
                    label: 'View',
                    icon: 'pi pi-eye',
                    command: () => {
                        this.getClickedRecord('view');
                    }
                });
            }  
            if(this.checkPermission(this.moduleId,'update')){
                this.actions.push({
                    label: 'Download',
                    icon: 'pi pi-download',
                    command: () => {
                        this.getClickedRecord('download');
                    }
                });
            } 
            if(this.checkPermission(this.moduleId,'update')){
                this.actions.push({
                    label: 'Move',
                    icon: 'pi pi-arrow-up',
                    command: () => {
                        this.getClickedRecord('move');
                    }
                });
            }     
            if(this.checkPermission(this.moduleId,'delete')){
                this.actions.push({
                    label: 'Delete',
                    icon: 'pi pi-times',
                    command: () => {
                        this.getClickedRecord('delete');
                    }
                });
            }    
             
            this.fileActions = this.actions;
            this.fileActions = this.fileActions.filter( item => item.label != 'Open');
            this.sharedFileActions = this.fileActions.filter( item => item.label != 'Open' && item.label != 'Delete' && item.label != 'Update');
		},
        computed: { 
        },
		mounted() { 
            this.getFileCategories(); 
			this.getRecords();  
            if(this.$route.query.view){
                this.urlParams = JSON.parse(this.$CryptoJS.AES.decrypt(this.$route.query.view, this.encryptKey).toString(this.$CryptoJS.enc.Utf8));
                if(Object.keys(this.urlParams).length){
                    this.urlParams.forEach( (parameter) => {
                        if(parameter.view == 'add-folder'){ 
                            this.selectedRecordId = parameter.id;
                            this.showForm = true; 
                        }  
                        else if(parameter.view == 'open-folder'){  
                            this.selectedRecordId = parameter.id; 
                            this.openFolder(this.selectedRecordId);
                        } 
                        else if(parameter.view == 'add-file'){ 
                            this.selectedRecordId = parameter.id; 
                            this.showUploadFilesForm = true; 
                        } 
                        else if(parameter.view == 'update-file'){ 
                            this.selectedRecordId = parameter.id;
                            this.selectedFileFolderId = parameter.folderId;
                            this.showFileForm = true; 
                        } 
                        else if(parameter.view == 'view-file'){ 
                            this.selectedRecordId = parameter.id;
                            this.selectedFileFolderId = parameter.folderId;
                            this.openFolder(this.selectedFileFolderId);
                            this.viewFileRecord(this.selectedRecordId);
                            this.detailSidebar = true; 
                        } 
                        else if(parameter.view == 'view-folder'){ 
                            this.selectedRecordId = parameter.id; 
                            this.openedFolder = parameter.folderId; 
                            this.openFolder(this.openedFolder);
                            this.viewRecord(this.selectedRecordId);
                            this.detailSidebar = true; 
                        } 
                        else if(parameter.view == 'selected-items'){ 
                            this.selectedFoldersAndFiles = parameter.selectedItems; 
                             
                        } 
                    })
                }
            } 
		},
		methods: {
            async getFileCategories(){
                var Vthis = this;  
                this.fileCategoriesLoading = true;
                await Vthis.axios.post('getAllFileCategories',{dropdown:true})
                .then( (response) => {   
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                       Vthis.fileCategories = response.data;   
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => {  
                    this.fileCategoriesLoading = false;
                })
            },  
            searchFilesAndFolders(){
                if(this.searchText.length > 2){
                    this.searchMessage = '';
                    var Vthis = this;
                    this.searching = true;
                    this.axios.post('searchFilesAndFolders',{text:this.searchText})
                    .then( (response) => { 
                        let responseStatus = this.printResponseResult(response,false); 
                        if(responseStatus == 200){  
                            this.searchMessage = response.data.length+' records found'; 
                            this.searchedData = response.data;  
                        }
                    })
                    .catch((error) => {   
                        this.printResponseResult(error);
                    })
                    .finally(() => { 
                        Vthis.searching = false;
                    })  
                } 
                else{
                    this.searchMessage = 'Type atleast 3 characters';
                }
            },
            fileseSelected(){
                let filesCounter = 0; 
                if(Object.keys(this.selectedFoldersAndFiles).length){ 
                    this.selectedFoldersAndFiles.forEach( (item) => {
                        if(item.indexOf('file_') >= 0){
                            filesCounter++;
                        }
                    });  
                }
                return filesCounter;
            }, 
            foldersSelected(){
                let foldersCounter = 0; 
                if(Object.keys(this.selectedFoldersAndFiles).length){ 
                    this.selectedFoldersAndFiles.forEach( (item) => {
                        if(item.indexOf('folder_') >= 0){
                            foldersCounter++;
                        }
                    });  
                }
                return foldersCounter;
            } , 
            clearAllSelectedFiles(){
                if(this.clearFilesSelection){ 
                    this.selectedFoldersAndFiles = this.selectedFoldersAndFiles.filter(fIndex => fIndex.indexOf('file_') < 0); 
                    this.clearFilesSelection = false;
                    this.selectedFileCheckbox = null;
                    this.removeViewFromURL(['selected-items']); 
                }
            },
            clearAllSelectedFolders(){ 
                this.selectedFoldersAndFiles = this.selectedFoldersAndFiles.filter(fIndex => fIndex.indexOf('folder_') < 0);  
                this.selectedFoldersCheckbox = null; 
            },
            selectAllFiles(){ 

                if(this.selectedFileCheckbox !== false){ 
                    if(this.files.length){
                        this.files.forEach( (file) => {
                            if(!this.selectedFoldersAndFiles.includes('file_'+file.id))
                            this.selectedFoldersAndFiles.push('file_'+file.id);
                        }) 
                    }
                    this.selectedFileCheckbox = true;
                }
                else{ 
                    if(this.files.length){
                        this.files.forEach( (file) => {
                            if(this.selectedFoldersAndFiles.includes('file_'+file.id))
                            this.selectedFoldersAndFiles = this.selectedFoldersAndFiles.filter(fIndex => fIndex !== 'file_'+file.id); 
                        }); 
                    }

                    let filesCounter = 0;
                    this.selectedFoldersAndFiles.filter( (fIndex) => {
                        if(fIndex.indexOf('file_') >= 0){
                            filesCounter++;
                        }
                    });
                   
                    if(filesCounter > 0){
                        this.clearFilesSelection = true; 
                        this.selectedFileCheckbox = false;
                    }
                    else{ 
                        this.selectedFileCheckbox = null;
                    }   
                }
                this.removeViewFromURL(['selected-items']); 
                this.addViewInURL({view: 'selected-items', selectedItems:this.selectedFoldersAndFiles}); 
            },
            selectAllFolders(){ 

                if(this.selectedFoldersCheckbox !== false){ 
                    if(this.records.length){
                        this.records.forEach( (folder) => {
                            if(!this.selectedFoldersAndFiles.includes('folder_'+folder.id))
                            this.selectedFoldersAndFiles.push('folder_'+folder.id);
                        }) 
                    }
                    this.selectedFoldersCheckbox = true;
                }
                else{ 
                    if(this.records.length){
                        this.records.forEach( (folder) => {
                            if(this.selectedFoldersAndFiles.includes('folder_'+folder.id))
                            this.selectedFoldersAndFiles = this.selectedFoldersAndFiles.filter(fIndex => fIndex !== 'folder_'+folder.id); 
                        }); 
                    }

                    let foldersCounter = 0;
                    this.selectedFoldersAndFiles.filter( (fIndex) => {
                        if(fIndex.indexOf('folder_') >= 0){
                            foldersCounter++;
                        }
                    }); 
                    if(foldersCounter > 0){ 
                        this.selectedFoldersCheckbox = false;
                    }
                    else{ 
                        this.selectedFoldersCheckbox = null;
                    }   
                }
                this.removeViewFromURL(['selected-items']); 
                this.addViewInURL({view: 'selected-items', selectedItems:this.selectedFoldersAndFiles}); 
            },
            checkIfAllCurrentFilesSelected(){
                if(Object.keys(this.files).length > 0){
                    let filesCounter = 0;
                    this.files.forEach( (file) => { 
                        this.selectedFoldersAndFiles.filter( (fIndex) => {
                            if(fIndex.indexOf('file_'+file.id) >= 0){
                                filesCounter++;
                            }
                        }); 
                    });
                    
                    if(filesCounter == Object.keys(this.files).length){
                        this.selectedFileCheckbox = true;  
                    }
                    else if(filesCounter > 0){
                        this.selectedFileCheckbox = false; 
                    }
                    else{ 
                        this.selectedFileCheckbox = null; 
                    } 
                }
                this.removeViewFromURL(['selected-items']); 
                this.addViewInURL({view: 'selected-items', selectedItems:this.selectedFoldersAndFiles}); 
            },
            checkIfAllCurrentFoldersSelected(){
                if(Object.keys(this.records).length > 0){
                    let foldersCounter = 0;
                    this.records.forEach( (folder) => { 
                        this.selectedFoldersAndFiles.filter( (fIndex) => {
                            if(fIndex.indexOf('folder_'+folder.id) >= 0){
                                foldersCounter++;
                            }
                        }); 
                    });
                    
                    if(foldersCounter == Object.keys(this.records).length){
                        this.selectedFoldersCheckbox = true;  
                    }
                    else if(foldersCounter > 0){
                        this.selectedFoldersCheckbox = false; 
                    }
                    else{ 
                        this.selectedFoldersCheckbox = null; 
                    } 
                }
                this.removeViewFromURL(['selected-items']); 
                this.addViewInURL({view: 'selected-items', selectedItems:this.selectedFoldersAndFiles}); 
            },
            removeURLView(){ 
                this.removeViewFromURL(['view-file','update-file','view-folder','open-folder','add-file','add-folder','selected-items']); 
            },
            closeDetailSidebar(){
                this.detailSidebar=false;
                this.removeViewFromURL(['view-file','view-folder']); 
            },
            fileActionsFilter($options){
                let newOptions = $options.filter( (option) => {
                    if(!['update','delete'].includes(option.label))
                    return option;
                });

                return newOptions;
            },
            onRightClick(event) {  
                window.scrollY = false;
                this.selectedRecordId = event.currentTarget.getAttribute('recordId');   
                this.selectedRecordType  = event.currentTarget.getAttribute('type'); 
                let contextKey  = event.currentTarget.getAttribute('contextKey'); 
                if(this.selectedRecordType == 'file'){ 
                     
                    if(contextKey > 0){
                        document.body.click(); 
                    }
                    
                    this.$refs['fileContextMenu'+contextKey].show(event);
                    this.selectedFileFolderId  = event.currentTarget.getAttribute('folderId');  
                    if(!event.currentTarget.classList.contains('selected')){  
                        event.currentTarget.classList.add("selected");
                        if(!this.selectedFoldersAndFiles.includes('file_'+this.selectedRecordId))
                        this.selectedFoldersAndFiles.push('file_'+this.selectedRecordId);
                    } 
                    //Mark Unmark checkbox if all files are selected or not
                    this.checkIfAllCurrentFilesSelected();
                }
                else if(this.selectedRecordType == 'folder'){
                    this.$refs.folderContextMenu.show(event);
                    if(!event.currentTarget.classList.contains('selected')){  
                        event.currentTarget.classList.add("selected");
                        if(!this.selectedFoldersAndFiles.includes('folder_'+this.selectedRecordId))
                        this.selectedFoldersAndFiles.push('folder_'+this.selectedRecordId);
                    } 
                    //Mark Unmark checkbox if all folders are selected or not
                    this.checkIfAllCurrentFoldersSelected();
                }
                
                return false;
                
            }, 
            selectFolder(folderId){
                if(event.currentTarget.classList.contains('selected')){
                    event.currentTarget.classList.remove("selected");
                    this.selectedFoldersAndFiles = this.selectedFoldersAndFiles.filter(data => data != 'folder_'+folderId); 
                }
                else{
                    event.currentTarget.classList.add("selected");
                    if(!this.selectedFoldersAndFiles.includes('folder_'+folderId))
                    this.selectedFoldersAndFiles.push('folder_'+folderId);
                } 

                //Mark Unmark checkbox if all folders are selected or not
                this.checkIfAllCurrentFoldersSelected();
            },
            selectFile(fileId){
                event.preventDefault();
                if(event.currentTarget.classList.contains('selected')){
                    event.currentTarget.classList.remove("selected");
                    this.selectedFoldersAndFiles = this.selectedFoldersAndFiles.filter(data => data != 'file_'+fileId); 
                }
                else{
                    event.currentTarget.classList.add("selected");
                    if(!this.selectedFoldersAndFiles.includes('file_'+fileId))
                    this.selectedFoldersAndFiles.push('file_'+fileId);
                } 
                
                //Mark Unmark checkbox if all files are selected or not
                this.checkIfAllCurrentFilesSelected();
            },
            openDetailSidebar(){
                this.detailSidebar = true;
            },
            openFolder(folderId){ 
                if(folderId != this.openedFolder){
                    this.clearAllSelectedFolders();
                    this.openedFolder = folderId; 
                    this.removeViewFromURL('open-folder');
                    this.addViewInURL({view: 'open-folder', id:this.openedFolder}); 
                    this.getRecords();
                    document.querySelectorAll('.mydrive-data-block').forEach(element => {
                        element.classList.remove('selected');
                    });  

                }  
            },
            goBack(){ 
                if(Object.keys(this.breadCrumb).length > 0){ 
                    this.breadCrumb = this.breadCrumb.filter((item,key) => { 
                        this.openedFolder = item.parent_id;  
                        //GO BACK BUTTON CLICKED 
                        if(key == (Object.keys(this.breadCrumb).length - 1 )){
                            this.removeViewFromURL('open-folder');
                            this.addViewInURL({view: 'open-folder', id:this.openedFolder});     
                            this.getRecords();
                            return false;   
                        }
                        else{
                            return item; 
                        }    
                    }); 
                } 
            },
            viewSearchedFile(){ 
                this.detailSidebar = true;
                this.selectedRecordId = event.currentTarget.getAttribute('recordId');  
                this.selectedRecordType  = event.currentTarget.getAttribute('type');   
                this.selectedFileFolderId  = event.currentTarget.getAttribute('folderId');  
                this.addViewInURL({view: 'view-file', id:this.selectedRecordId,folderId:this.selectedFileFolderId});
                this.viewFileRecord();
            },
            getClickedRecord(action){ 
                if(action == 'delete'){  
                    this.deleteRecord(); 
                }
                else if(action == 'open'){  
                    this.openFolder(this.selectedRecordId);  
                }
                else if(action == 'view'){ 
                    if(this.selectedRecordType == 'folder'){
                        this.addViewInURL({view: 'view-folder', id:this.selectedRecordId,folderId:this.openedFolder});
                        this.viewRecord(); 
                    }
                    else{ 
                        this.addViewInURL({view: 'view-file', id:this.selectedRecordId,folderId:this.selectedFileFolderId});
                        this.viewFileRecord();
                    }   
                    this.detailSidebar = true;
                }  
                else if(action == 'update'){ 
                    if(this.selectedRecordType == 'folder'){
                        this.addViewInURL({view: 'add-folder', id:this.selectedRecordId});
                        this.showForm = true; 
                    }
                    else{
                        this.addViewInURL({view: 'add-file', id:this.selectedRecordId,folderId:this.selectedFileFolderId});
                        this.showFileForm = true; 
                    }  
                }
                else if(action == 'download'){ 
                    this.downloadSelectedItems(); 
                }
                else if(action == 'move'){ 
                    this.moveFileSidebar = true;
                    this.fetchFoldersTree();  
                }

                
            },
            toggleRecordcontextMevent() { 
                this.$refs.menu.toggle(event);
                this.selectedRecordId = event.currentTarget.getAttribute('recordId');  
                this.selectedRecordType  = event.currentTarget.getAttribute('type');   
			},
            loadForm(){ 
                this.selectedRecordId = 0;
                this.addViewInURL({view: 'add-folder', id:this.selectedRecordId});
                this.showForm = true;
            },
            closeForm(){ 
                if(!this.selectedRecordId){
                    this.getRecords();
                }
                this.removeViewFromURL('add-folder');
                this.showForm = false;
            }, 
            closeFileForm(){ 
                if(!this.selectedRecordId){
                    this.getRecords();
                }
                this.removeViewFromURL('update-file');
                this.showFileForm = false;
            }, 
            closeUploadFilesForm(){ 
                this.showUploadFilesForm = false;
            },
            getRecords(){  
                var Vthis = this;
                this.loading = true; 
                this.files = this.records = []; 
                this.axios.post('getAllFolders',{parent_id:this.openedFolder,categories:this.selected_file_categories})
                .then( (response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        if(response){ 
                            
                            this.records = response.data.records;  
                            this.files = response.data.files;  

                            if(this.records.length){
                                let filteredSelection  = [];
                                this.selectedFoldersAndFiles.forEach( (SelectedItem) => { 
                                    if(SelectedItem.indexOf('folder_') >= 0){ 
                                        this.records.forEach( (folder) => {
                                            if(SelectedItem == 'folder_'+folder.id)
                                            filteredSelection.push(SelectedItem);
                                        })  
                                    }
                                    else{
                                        filteredSelection.push(SelectedItem);
                                    }  
                                }) 

                                this.selectedFoldersAndFiles = filteredSelection;
                            }

                            if(Object.keys(response.data.breadCrumb).length){
                                Vthis.breadCrumb = [];
                                response.data.breadCrumb.forEach( (folder) => {
                                    Vthis.breadCrumb.push({id:folder.id, name:folder.name,parent_id:folder.parent_id});
                                })
                            }  

                            //Mark Unmark checkbox if all files are selected or not
                            this.checkIfAllCurrentFilesSelected();
                            //Mark Unmark checkbox if all files are selected or not
                            this.checkIfAllCurrentFoldersSelected();
                        } 
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.loading = false; 
                })  
            }, 
            viewRecord(){ 
                this.selectedFileData = this.selectedFolderData = [];
                var Vthis = this;
                this.folderFileViewLoading = true;
                this.axios.post('getAllFolders',{id:this.selectedRecordId})
                .then( (response) => { 
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){   
                        this.selectedFolderData = response.data.records[0];  
                    }
                })
                .catch((error) => {   
                    this.printResponseResult(error);
                })
                .finally(() => { 
                    this.folderFileViewLoading = false;
                })  
            },
            viewFileRecord(){ 
                this.selectedFileData = this.selectedFolderData = [];
                var Vthis = this;
                this.folderFileViewLoading = true;
                this.axios.post('getFolderFiles',{id:this.selectedRecordId, folder_id:this.selectedFileFolderId})
                .then( (response) => {   
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        if(response.data){
                            if(response.data[0]){  
                                this.selectedFileData = response.data[0];   
                            }
                        } 
                    }
                })
                .catch((error) => {   
                    this.printResponseResult(error);
                })
                .finally(() => { 
                    this.folderFileViewLoading = false;
                })  
            },
            async fetchFoldersTree(){
                this.moveToFolderId = 0; 
                var Vthis = this;
                this.fetchingFoldersTree = true;
                await this.axios.post('fetchFoldersTree', {id:this.selectedRecordId, type:this.selectedRecordType})
                .then( (response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        this.allFoldersTree = response.data;
                    }
                })
                .catch((error) => {   
                    this.printResponseResult(error);
                })
                .finally(() => { 
                    this.fetchingFoldersTree = false;
                }) 
                
            },
            async moveSelectedItem(){ 
                var Vthis = this;
                this.movingItem = true;
                let moveTo = 0;
                if(this.moveToFolderId){
                    if(Object.keys(this.moveToFolderId)){
                        moveTo = Object.keys(this.moveToFolderId)[0];
                    }
                } 
                 
                this.moveToFolderId = 0;
                await this.axios.post('moveSelectedItem', {id:this.selectedRecordId, type:this.selectedRecordType, moveToFolder:moveTo },
                {
                    onUploadProgress: (progressEvent) => {
                        Vthis.movedPercentage = ((progressEvent.loaded / progressEvent.total) * 100).toFixed(2);
                    }
                })
                .then( (response) => {
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){   
                        let responseStatus = this.printResponseResult(response);
                        if(responseStatus == 200){
                            this.moveFileSidebar = false;
                            this.getRecords();
                        } 
                    }
                })
                .catch((error) => {    
                    let message = Vthis.customError(error ); 
                    Vthis.showMessage(message,'error'); 
                })
                .finally(() => { 
                    this.movingItem = false;
                })  
            },
            async downloadSelectedItems(){
                if(Object.keys(this.selectedFoldersAndFiles).length > 0){
                    var Vthis = this;
                    this.loading = true;
                    await this.axios.post('downloadSelectedItems',{items:this.selectedFoldersAndFiles})
                    .then( (response) => { 
                        let responseStatus = this.printResponseResult(response,false); 
                        if(responseStatus == 200){ 
                            if(response.data.downlaod_link){ 
                                this.downloadURL = response.data.downlaod_link;
                                this.downloadFileName = response.data.downlaod_file_name;
                                document.getElementById('downloadURL').click(); 
                            } 
                        }
                    })
                    .catch((error) => {   
                        this.printResponseResult(error);
                    })
                    .finally(() => { 
                        this.loading = false;
                    }) 
                }
            },
            deleteRecord(){ 
                var Vthis = this;
                this.$confirm.require({
                    group: 'dialog',
                    header: 'Confirmation',
                    message: 'Are you sure you want to delete '+Object.keys(this.selectedFoldersAndFiles).length+' items?',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => { 
                        Vthis.loading = true;  
                        if(!this.selectedFoldersAndFiles.includes(this.selectedRecordType+'_'+this.selectedRecordId))
                        this.selectedFoldersAndFiles.push(this.selectedRecordType+'_'+this.selectedRecordId);
                        if(Object.keys(this.selectedFoldersAndFiles).length){
                         
                            // let filterdRecords = Vthis.records.filter(val => Vthis.selectedRecords.includes(val)); 
                            // filterdRecords.forEach((data) => {
                            //     DeleteAbleRecords.push(data.id);
                            // })  
                            
                            Vthis.axios.post('deleteFolderAndFiles',{id:this.selectedFoldersAndFiles})
                            .then( (response) => {  
                                let responseStatus = this.printResponseResult(response,false); 
                                if(responseStatus == 200){ 
                                    let responseStatus = this.printResponseResult(response);
                                    if(responseStatus == 200){
                                        this.selectedFoldersAndFiles = [];
                                        document.querySelectorAll('.mydrive-data-block').forEach(element => {
                                            element.classList.remove('selected');
                                        });  
                                        Vthis.getRecords();
                                    }  
                                }
                            })
                            .catch((error) => {    
                                this.printResponseResult(error);
                            })
                            .finally(() => { 
                                Vthis.loading = false;
                            })
                        }
                    },
                    reject: () => {
                        // this.loading = false;
                    }
                }); 
            },
           
            isRTL() {
				return this.$appState.RTL;
			}
		},
        watch : { 
        }
}
</script>
 
