<template>  
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :dismissable="false" :showCloseIcon="false" @hide="closeForm" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'"> 
            <form @submit.prevent="login" @keydown="form.onKeydown($event)" enctype="multipart/form-data"> 
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0 mydrive-breadcurmb">
                        <h3 class="mb-0">{{module_name}}</h3>   
                        <div class="breadcrumb mt-2"> 
                            <a href="javascript:;" style="float:left"><span class="pi pi-circle-fill"> My Drive</span></a>
                            <Breadcrumb :home="{}" :model="uploadPath">
                                <template #item="{item,key}">
                                    <a :class=" item.id > 0 && key <= (Object.keys(uploadPath).length-2)? 'link' : ''"  >{{item.name}}</a>
                                </template>
                            </Breadcrumb>
                        </div> 
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">   
                        <div class="col-12 lg:col-8 sm:col-12 md:col-8">
                            <div class="col-12 lg:col-12 sm:col-12 md:col-12 p-0" v-if="checkPermission(61,'dropdown')">
                                <label>File Category</label>
                                <Dropdown v-model="form.file_category_id" :options="fileCategories" optionLabel="name" optionValue="id" placeholder="Select Category" class="full-width"/>
                                <HasError class="p-error" :form="form" field="file_category_id" />
                            </div>
                            <ProgressBar v-if="uploadPercentage" :value="uploadPercentage" style="height:30px" :showValue="true" /> <br />
                            <!-- :maxFileSize="100000000" IS 100MB -->
                                <FileUpload 
                                    name="files[]"  
                                    @uploader="fileUploader"
                                    :showUploadButton="false" 
                                    :customUpload="true" 
                                    @select="myUploader"
                                    :multiple="true" 
                                    accept="" 
                                    :maxFileSize="100000000" 
                                    :fileLimit="25" 
                                >
                                    <template #empty>
                                        <p>Drag and drop files to here to upload.</p>
                                    </template>
                                </FileUpload>
                        </div>  
                        
                        <div class="col-12 lg:col-4 sm:col-12 md:col-4">
                            <div>  
                                <Panel v-if="!usersLoading && checkPermission(16,'dropdown')" class="mb-3" header="Share These Files With" :toggleable="true">
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2">   
                                        <div class="field-checkbox">
                                            <Checkbox @click="selectAllUsers()" value="true" v-model="selectAllUsersCheckBox" :binary="true" />
                                            <label >Select All</label>
                                        </div>
                                        <HasError class="p-error" :form="form" field="users" /> 
                                        <Listbox v-model="form.users" filterPlaceholder="Select Users" checkbox="true" :multiple="true" :filter="true" :showClear="true" :options="users" optionLabel="name"  optionValue="id" class="full-width" />
                                    </div> 
                                </Panel>  
                                <div v-else> 
                                    <div class="col-12 md:col-12 lg:col-12" style="float:left">
                                        <div><Skeleton height="5rem"></Skeleton></div>
                                    </div>
                                    <div class="col-12 md:col-12 lg:col-12" style="float:left">
                                        <div><Skeleton height="2rem"></Skeleton></div>
                                    </div> 
                                    <div class="col-12 md:col-12 lg:col-12" style="float:left">
                                        <div><Skeleton height="2rem"></Skeleton></div>
                                    </div> 
                                    <div class="col-12 md:col-12 lg:col-12" style="float:left">
                                        <div><Skeleton height="2rem"></Skeleton></div>
                                    </div>  
                                    <div class="col-12 md:col-12 lg:col-12" style="float:left">
                                        <div><Skeleton height="2rem"></Skeleton></div>
                                    </div>  
                                    <div class="col-12 md:col-12 lg:col-12" style="float:left">
                                        <div><Skeleton height="2rem"></Skeleton></div>
                                    </div>  
                                    <div class="col-12 md:col-12 lg:col-12" style="float:left">
                                        <div><Skeleton height="2rem"></Skeleton></div>
                                    </div>  
                                </div>
                            </div>   
                        </div>
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <Button type="submit" label="Save" :loading="isSaving" icon="pi pi-check" @click="save()" class="p-button-raised p-button-lg p-button-success mr-2"/>
                                <Button type="submit" label="Save and Close" :loading="isSaving" icon="pi pi-check" @click="saveAndClose=true, save()" class="p-button-raised p-button-lg p-button-info mr-2"/>
                                <Button type="button" label="Reset" icon="pi pi-refresh" class="p-right p-button-raised p-button-lg p-button-warning mr-2" @click="resetForm()"/>
                            </template>
                            <template v-slot:end> 
                                <Button type="button" label="Cancel" icon="pi pi-times" class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeForm"/>
                            </template> 
                        </Toolbar>   
                    </div>
                </div> 
            </form>
        </Sidebar> 
    </BlockUI>
    
</template>
<script> 
export default {
    data() {
			return {  
                form: new this.Form({ 
                    id: '', 
                    folder_id: 0, 
                    file_category_id : 0,
                    files: [], 
                    users:[], 
                }),   
                CompanyLoading:false,
                module_name:'Upload Files',  
                isSaving:false,  
                saveAndClose:false, 
                recId:0,
                loadingComponent:true,
                fileCategoriesLoading:false,
                usersLoading:false,
                fileCategories:[],
                formVisible:false, 
                uploadPercentage:0,
                companies:[],   

                selectAllUsersCheckBox:false,
                users:[],
                user:[],
			}
		},   
        props:{
            showForm : {
                type : Boolean,
                required : true,
            },
            recordId :{
                type : Number,
            },
            openedFolder:{
                type: Number,
            },
            uploadPath: {
                type: Object,
            }
        },
		created() {   
		},
        computed: { 
        },
		mounted() { 
            this.user = JSON.parse(localStorage.getItem('user'));  
            this.resetForm();
            this.form.folder_id = this.openedFolder;
            this.formVisible = this.showForm; 
            this.recId = this.recordId;    
            this.getUsers();
            this.getFileCategories(); 
		},
		methods: { 
            async getFileCategories(){
                var Vthis = this;  
                this.fileCategoriesLoading = true;
                await Vthis.axios.post('getAllFileCategories',{dropdown:true})
                .then( (response) => {   
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                       Vthis.fileCategories = response.data;   
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => {  
                    this.fileCategoriesLoading = false;
                })
            },  
            async getUsers(){
                var Vthis = this;  
                this.usersLoading = true;
                await Vthis.axios.post('getAllUsers',{dropdown:true})
                .then( (response) => {   
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        // Vthis.users = response.data;  
                        var users = response.data;  
                        if(users){
                            users = users.forEach( (rec) => {
                                if(rec.id != Vthis.user.id)
                                Vthis.users.push(rec);
                            }) 
                        }  
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => {  
                    this.usersLoading = false;
                })
            },  
            selectAllUsers(){ 
               if(!this.selectAllUsersCheckBox){
                   this.users.forEach((user) => {
                       this.form.users.push(user.id);
                   });
               }
               else{
                   this.form.users = [];
               }
            },
            resetForm(){
                this.form.reset();
            },
            async save(){   
                var Vthis = this; 
                this.isSaving = true;
                await Vthis.form
                .post(Vthis.$baseurl+'api/saveFiles', {
                    onUploadProgress: (progressEvent) => {
                        Vthis.uploadPercentage = ((progressEvent.loaded / progressEvent.total) * 100).toFixed(2);
                    }
                })
                .then((response) => { 
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        if(!this.recId){
                            //  this.getRecords();
                        }
                        // this.getRecords();
                        if(this.saveAndClose){   
                            //  this.getRecords();
                            this.saveAndClose = false;
                            setTimeout( () => {this.closeForm();},1000);
                            // this.closeForm();
                        }   
                        Vthis.showMessage('Saved successfully','success',); 
                    }
                })
                .catch((error) => {   
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.isSaving = false; 
                }) 
            }, 
            loadForm(){
                this.resetForm(); 
            },
            closeForm(){
                this.$emit('formClose',true); 
            }, 
            
            myUploader(event){   
                this.form.files = event.files;
            }, 
            async fileUploader() {
                await this.form
                .post(Vthis.$baseurl+'api/saveFiles', Vthis.form)
                .then((response) => { 
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        Vthis.showMessage('Saved successfully','success'); 
                        if(!this.recId){
                            this.resetForm();
                        } 
                        // this.getRecords();
                        if(this.saveAndClose){   
                            this.saveAndClose = false;
                            setTimeout( () => {this.closeForm();},1000); 
                        }  
                    }
                })
                .catch((error) => {   
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.isSaving = false; 
                }) 
            },    
		},
        watch : {
             
        }
}
</script>
 
